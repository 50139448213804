/*
 * @Author: Axs <i@axs.email>
 * @Date: 2020-07-17 14:51:21
 * @LastEditors: Axs <i@axs.email>
 * @LastEditTime: 2021-07-30 16:33:05
 * @Description:
 * @FilePath: /src/api/modules/order.js
 */
/**
 * 创建肯德基订单
 */
export const CREATRE_KFC_ORDER = '/api/kfcOrder/createOrder';
/**
 * 创建肯德基外卖订单
 */
export const CREATRE_OUT_BY_KFC_ORDER = '/api/kfcOrder/createTakeoutOrder4Cart';
/**
 * 获取肯德基订单
 */
export const GET_KFC_ORDER_DETAIL = '/api/kfcOrder/detail';
/**
 * 获取肯德基混合订单
 */
export const GET_KFC_ORDER_DETAIL_BLEND = '/api/kfcMainOrder/getById';
/**
 * 获取肯德基订单列表
 */
export const GET_KFC_ORDER_LIST = '/api/kfcOrder/pagedQueryUserOrder';
/**
 * 获取肯德基订单详情 (orderNo)
 */
export const GET_KFC_ORDER_INFO_OF_ORDER_NO = 'api/kfcOrder/getByOrderNo';
/**
 * 肯德基获取加购商品
 */
export const GET_KFC_SPECIAL_PRODUCT = '/api/kFCProductComboSpecial/listByStoreCode';
/**
 * 获取用户手机号
 */
export const GET_USER_MOBLIE = '/api/account/getMobileNo';
/**
 * 获取上一个订单手机号
 */
export const GET_LAST_ORDER_MOBILE = '/api/account/getLatsOrderMobileNo';
/**
 * 获取订单中商品列表
 */
export const GET_ORDER_PRODUCT_ITEM = '/api/kfcOrderItems/listTicketVos';
/**
 * 通过阿奇索创建订单
 */
export const AGISO_IDLE_BY_KFC_ORDER = '/api/kfcOrder/createOrder4AgisoIdle';
/**
 * 使用阿奇索订单获取千猪订单号
 */
export const GET_ORDER_NO_BY_AGISO = '/api/agisoIdleOrder/getByAgisoOrderNo';
/**
 * 查询会员码
 */
export const GET_MEMBER_CODE = '/api/kfcOrderItems/queryMemberCode';
/**
 * 查询支付码
 */
export const GET_PAY_CODE = '/api/kfcOrderItems/queryPaymentCode';
/**
 * 查看快递信息
 */
export const GET_EXPRESS_INFO = '/api/kfcOrderItems/queryExpress';